import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import { auth } from '../services/firebase'

const routes = [
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/dashboard',
    redirect: '/'
  },
  {
    path: '/',
    alias: '/formularios',
    name: 'Formularios',
    component: () => import('../views/Formularios.vue'),
    children: [
      {
        path: 'cotizaciones',
        name: 'Cotizaciones',
        component: () => import('../views/Cotizaciones.vue'),
        meta: {
          breadcrumb: [
            { name: 'Formularios' },
            { name: 'Cotizaciones' }
          ]
        }
      },
      {
        path: 'contactos',
        name: 'Contactos',
        component: () => import('../views/Contactos.vue'),
        meta: {
          breadcrumb: [
            { name: 'Formularios' },
            { name: 'Contactos' }
          ]
        }
      },
      {
        path: 'llamados',
        name: 'Llamados',
        component: () => import('../views/Llamados.vue'),
        meta: {
          breadcrumb: [
            { name: 'Formularios' },
            { name: 'Llamados' }
          ]
        }
      },
      {
        path: 'simulaciones',
        name: 'Simulaciones',
        component: () => import('../views/Simulaciones.vue'),
        meta: {
          breadcrumb: [
            { name: 'Formularios' },
            { name: 'Simulaciones' }
          ]
        }
      },
      {
        path: 'puntajes',
        name: 'Puntajes',
        component: () => import('../views/Puntajes.vue'),
        meta: {
          breadcrumb: [
            { name: 'Formularios' },
            { name: 'Puntajes' }
          ]
        }
      },
      // {
      //   path: 'prestamo-computadores',
      //   name: 'Prestamo-Computadores',
      //   component: () => import('../views/PrestamoComputadores.vue'),
      //   meta: {
      //     breadcrumb: [
      //       { name: 'Formularios' },
      //       { name: 'Prestamo-Computadores' }
      //     ]
      //   }
      // },
      // {
      //   path: 'apelacion-conectividad',
      //   name: 'Apelacion-Conectividad',
      //   component: () => import('../views/ApelacionConectividad.vue'),
      //   meta: {
      //     breadcrumb: [
      //       { name: 'Formularios' },
      //       { name: 'Apelacion-Conectividad' }
      //     ]
      //   }
      // },
      // {
      //   path: 'prospeccion',
      //   name: 'Prospeccion',
      //   component: () => import('../views/Prospeccion.vue'),
      //   meta: {
      //     breadcrumb: [
      //       { name: 'Formularios' },
      //       { name: 'Prospeccion' }
      //     ]
      //   }
      // },
      // {
      //   path: 'orientadores',
      //   name: 'Orientadores',
      //   component: () => import('../views/Orientadores.vue'),
      //   meta: {
      //     breadcrumb: [
      //       { name: 'Formularios' },
      //       { name: 'Orientadores' }
      //     ]
      //   }
      // },
      // {
      //   path: 'estudiantes',
      //   name: 'Estudiantes',
      //   component: () => import('../views/Estudiantes.vue'),
      //   meta: {
      //     breadcrumb: [
      //       { name: 'Formularios' },
      //       { name: 'Estudiantes' }
      //     ]
      //   }
      // },
      // {
      //   path: 'formulario-gratuidad-cft',
      //   name: 'Formulario-Gratuidad-CFT',
      //   component: () => import('../views/FormularioGratuidadCFT.vue'),
      //   meta: {
      //     breadcrumb: [
      //       { name: 'Formularios' },
      //       { name: 'Formulario-Gratuidad-CFT' }
      //     ]
      //   }
      // },
      // {
      //   path: 'formulario-gratuidad-ust',
      //   name: 'Formulario-Gratuidad-UST',
      //   component: () => import('../views/FormularioGratuidadUST.vue'),
      //   meta: {
      //     breadcrumb: [
      //       { name: 'Formularios' },
      //       { name: 'Formulario-Gratuidad-UST' }
      //     ]
      //   }
      // },
      // {
      //   path: 'formulario-gratuidad-ip',
      //   name: 'Formulario-Gratuidad-IP',
      //   component: () => import('../views/FormularioGratuidadIP.vue'),
      //   meta: {
      //     breadcrumb: [
      //       { name: 'Formularios' },
      //       { name: 'Formulario-Gratuidad-IP' }
      //     ]
      //   }
      // },
      // {
      //   path: 'formulario-matricula',
      //   name: 'Formulario-Matricula',
      //   component: () => import('../views/FormularioMatricula.vue'),
      //   meta: {
      //     breadcrumb: [
      //       { name: 'Formularios' },
      //       { name: 'Formulario-Matricula' }
      //     ]
      //   }
      // },
      // {
      //   path: 'formulario-actualizacion',
      //   name: 'Formulario-Actualizacion',
      //   component: () => import('../views/FormularioActualizacion.vue'),
      //   meta: {
      //     breadcrumb: [
      //       { name: 'Formularios' },
      //       { name: 'Formulario-Actualizacion' }
      //     ]
      //   }
      // },
      // {
      //   path: 'formularios-incompletos',
      //   name: 'Formularios-Incompletos',
      //   component: () => import('../views/FormularioIncompletos.vue'),
      //   meta: {
      //     breadcrumb: [
      //       { name: 'Formularios' },
      //       { name: 'Formularios-Incompletos' }
      //     ]
      //   }
      // },
      // {
      //   path: 'formularios-admisiones',
      //   name: 'Admision-Especial',
      //   component: () => import('../views/FormularioAdmisiones.vue'),
      //   meta: {
      //     breadcrumb: [
      //       { name: 'Formularios' },
      //       { name: 'Admision-Especial' }
      //     ]
      //   }
      // },
      // {
      //   path: 'formularios-admisiones-extranjeros',
      //   name: 'Admision-Especial-Extranjeros',
      //   component: () => import('../views/FormularioAdmisionesExtranjeros.vue'),
      //   meta: {
      //     breadcrumb: [
      //       { name: 'Formularios' },
      //       { name: 'Admision-Especial-Extranjeros' }
      //     ]
      //   }
      // },
      // {
      //   path: 'formularios-beca-sello',
      //   name: 'Beca-sello',
      //   component: () => import('../views/FormularioBecaSello.vue'),
      //   meta: {
      //     breadcrumb: [
      //       { name: 'Formularios' },
      //       { name: 'Beca-sello' }
      //     ]
      //   }
      // },
      // {
      //   path: 'formularios-nem',
      //   name: 'Nem',
      //   component: () => import('../views/Nem.vue'),
      //   meta: {
      //     breadcrumb: [
      //       { name: 'Formularios' },
      //       { name: 'Nem' }
      //     ]
      //   }
      // },
      // {
      //   path: 'formularios-repostulacion',
      //   name: 'Formulario Repostulación',
      //   component: () => import('../views/FormularioRepostulacion.vue'),
      //   meta: {
      //     breadcrumb: [
      //       { name: 'Formularios' },
      //       { name: 'Formulario Repostulación' }
      //     ]
      //   }
      // },
      {
        path: 'pregrado-online',
        name: 'Pregrado online',
        component: () => import('../views/Online.vue'),
        meta: {
          breadcrumb: [
            { name: 'Formularios' },
            { name: 'Pregrado online' }
          ]
        }
      },
      //NUEVOS FORMULARIOS
      {
        path: 'formulario-prospecto',
        name: 'Prospecto',
        component: () => import('../views/Prospectos.vue'),
        meta: {
          breadcrumb: [
            { name: 'Formularios' },
            { name: 'Prospecto' }
          ]
        }
      },
      {
        path: 'formulario-admision-especial',
        name: 'Admisión-Especial',
        component: () => import('../views/FormularioSpecialAdmission.vue'),
        meta: {
          breadcrumb: [
            { name: 'Formularios' },
            { name: 'Admisión-Especial' }
          ]
        }
      },
      {
        path: 'formulario-contactos',
        name: 'Contacto',
        component: () => import('../views/Contact.vue'),
        meta: {
          breadcrumb: [
            { name: 'Formularios' },
            { name: 'Contacto' }
          ]
        }
      },
      {
        path: 'formulario-clicktocall',
        name: 'Llamado',
        component: () => import('../views/Clicktocall.vue'),
        meta: {
          breadcrumb: [
            { name: 'Formularios' },
            { name: 'Llamado' }
          ]
        }
      },
      {
        path: 'formulario-nem',
        name: 'Formulario-Nem',
        component: () => import('../views/EloquaNem.vue'),
        meta: {
          breadcrumb: [
            { name: 'Formularios' },
            { name: 'Nem' }
          ]
        }
      },
      {
        path: 'formulario-stem',
        name: 'Stem',
        component: () => import('../views/EloquaStem.vue'),
        meta: {
          breadcrumb: [
            { name: 'Formularios' },
            { name: 'Stem' }
          ]
        }
      },
      {
        path: 'formulario-beca-sello',
        name: 'Formulario-Beca-Sello',
        component: () => import('../views/EloquaBecaSello.vue'),
        meta: {
          breadcrumb: [
            { name: 'Formularios' },
            { name: 'Formulario-Beca-Sello' }
          ]
        }
      },
      {
        path: 'formulario-actualizacion-datos',
        name: 'Formulario-Actualizacion-Datos',
        component: () => import('../views/EloquaFormularioActualizacion.vue'),
        meta: {
          breadcrumb: [
            { name: 'Formularios' },
            { name: 'Formulario-Actualizacion-Datos' }
          ]
        }
      },
      {
        path: 'formulario-pregrado-presencial',
        name: 'Formulario-Pregrado-Presencial',
        component: () => import('../views/EloquaPregradoPresencial.vue'),
        meta: {
          breadcrumb: [
            { name: 'Formularios' },
            { name: 'Formulario-Pregrado-Presencial' }
          ]
        }
      },
      {
        path: 'formulario-repostulacion',
        name: 'Formulario-Repostulacion',
        component: () => import('../views/EloquaRepostulacion.vue'),
        meta: {
          breadcrumb: [
            { name: 'Formularios' },
            { name: 'Formulario-Repostulacion' }
          ]
        }
      },
      {
        path: 'formulario-eventos-alumnos',
        name: 'Formulario-Eventos-Alumnos',
        component: () => import('../views/EloquaEventosAlumnos.vue'),
        meta: {
          breadcrumb: [
            { name: 'Formularios' },
            { name: 'Formulario-Eventos-Alumnos' }
          ]
        }
      },
      {
        path: 'formulario-eventos-orientador',
        name: 'Formulario-Eventos-Orientador',
        component: () => import('../views/EloquaEventosOrientador.vue'),
        meta: {
          breadcrumb: [
            { name: 'Formularios' },
            { name: 'Formulario-Eventos-Orientador' }
          ]
        }
      },
      {
        path: 'formulario-simulador-puntaje',
        name: 'Formulario-Simulador-Puntaje',
        component: () => import('../views/EloquaSimuladorPuntaje.vue'),
        meta: {
          breadcrumb: [
            { name: 'Formularios' },
            { name: 'Formulario-Simulador-Puntaje' }
          ]
        }
      },
      {
        path: 'formulario-retiros',
        name: 'Formulario-Retiros',
        component: () => import('../views/FormularioRetiros.vue'),
        meta: {
          breadcrumb: [
            { name: 'Formularios' },
            { name: 'Formulario-Retiros' }
          ]
        }
      },
      {
        path: 'actualizacion-egresados',
        name: 'Actualizacion-Egresados',
        component: () => import('../views/ActualizacionEgresados.vue'),
        meta: {
          breadcrumb: [
            { name: 'Formularios' },
            { name: 'Actualizacion-Egresados' }
          ]
        }
      },
      {
        path: 'actualizacion-dae',
        name: 'Actualizacion-DAE',
        component: () => import('../views/ActualizacionDae.vue'),
        meta: {
          breadcrumb: [
            { name: 'Formularios' },
            { name: 'Actualizacion-DAE' }
          ]
        }
      },
    ],
    meta: { requiresAuth: true }
  },
  {
    path: '/ferias',
    name: 'Ferias',
    component: () => import('../views/Ferias/Ferias.vue'),
    meta: { requiresAuth: true, requirePermission: ['isFerias'] }
  },
  {
    path: '/ferias/:slug',
    name: 'SingleFerias',
    component: () => import('../views/Ferias/FormularioFeria.vue'),
    meta: {
      breadcrumb: [
        { name: 'Ferias' },
      ],
      requiresAuth: true,
      requirePermission: ['isFerias']
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active"
})


/* router.beforeEach((to, _, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  if (requiresAuth) {
    auth.onAuthStateChanged(user => {
      if (!user) {
        router.replace({ name: "Login" });
      } else next()
    });
  } else next()
}); */


router.beforeEach(async (to, _, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  //const requirePermission = to.matched.some(x => x.meta.requirePermission); // Boolean
  const requirePermission = to?.meta?.requirePermission; // Object

  if (requiresAuth) {
    auth.onAuthStateChanged(user => {
      if (!user) {
        router.replace({ name: "Login" });
      }
    });
  }

  if (requirePermission) {
    let result = false;
    try {
      await auth.currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          let userClaims = idTokenResult?.claims || []
          let coincidenceClaims = Object.keys(userClaims).filter(value => requirePermission.includes(value));
          result = coincidenceClaims;
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }

    if (!result) {
      router.push('/');
    }
  }

  next();
});

export default router
